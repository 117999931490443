import React from 'react';
import './App.css';

const App = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="container">
      <h1>hndg.info</h1>
      <p>This website is currently in development and will be released soon.</p>
      <img src="/UnderConstruction.png" alt="Under Construction" className="under-construction-image" />
      <footer>
        © 2012 - {currentYear} <a href="https://www.visiontweak.com/" className="footer-link">VisionTweak</a>
      </footer>
    </div>
  );
}

export default App;